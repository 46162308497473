export default {
  state: () => ({
    categories: [
      'Premiação Torneio',
      'Compra de Jogos Usados',
      'Crédito Bazar',
      'Cashback'
    ]
  }),
}
