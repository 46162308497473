import { createStore } from 'vuex'

import CheckingAccountBalanceModule from './CheckingAccount/Balance'
import AccountCustomer from './AccountCustomer/Customer'
import DepositCategory from './Deposit/Category'
import LoadingPage from './LoadingPage/Loading'

export default createStore({
  modules: {
    balance: CheckingAccountBalanceModule,
    accountCustomer: AccountCustomer,
    depositCategory: DepositCategory,
    loadingPage: LoadingPage
  },
  state: {
    showBottomBar: false,
    buttonsBottomBar: []
  },
  mutations: {
    buildBottomBar: (state, payload) => {
      state.showBottomBar = true
      state.buttonsBottomBar = payload
    },
    clearBottomBar: (state) => {
      state.showBottomBar = false
      state.buttonsBottomBar = []
    }
  }
})
