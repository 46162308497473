import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Application",
    meta: { requiresAuth: true },
    component: () => import("../views/Layouts/ApplicationLayout.vue"),
    children: [
      {
        path: "/checkingaccount",
        name: "CheckingAccount",
        meta: { requiresAuth: true },
        component: () => import("../views/CheckingAccount/Layout/Principal.vue"),
        children: [
          {
            path: "/checkingaccount/balance",
            name: "CheckingAccountBalance",
            meta: { requiresAuth: true },
            component: () => import("../views/CheckingAccount/Balance.vue")
          },
          {
            path: "/checkingaccount/statement",
            name: "CheckingAccountStatement",
            meta: { requiresAuth: true },
            component: () => import("../views/CheckingAccount/Statement.vue")
          },
          {
            path: "/checkingaccount/statementdetail/:statementId",
            name: "CheckingAccountStatementDetail",
            meta: { requiresAuth: true },
            component: () => import("../views/CheckingAccount/StatementDetail.vue")
          },
          {
            path: "/checkingaccount/transfer",
            name: "CheckingAccountTransfer",
            meta: { requiresAuth: true },
            component: () => import("../views/CheckingAccount/Transfer.vue")
          }
        ]
      },
      {
        path: "/customer",
        name: "Customer",
        meta: { requiresAuth: true },
        component: () => import("../views/Customer/Layout/Principal.vue"),
        children: [
          {
            path: "/customer/main",
            name: "CustomerMain",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/Main.vue")
          },
          {
            path: "/customer/new",
            name: "CustomerNew",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/New.vue")
          },
          {
            path: "/customer/credit",
            name: "CustomerCredit",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/Credit.vue")
          },
          {
            path: "/customer/statement",
            name: "CustomerStatement",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/Statement.vue")
          },
          {
            path: "/customer/statementdetail/:statementId",
            name: "CustomerStatementDetail",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/StatementDetail.vue")
          },
          {
            path: "/customer/subscriptions",
            name: "CustomerSubscriptions",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/Subscription/Subscriptions.vue")
          },
          {
            path: "/customer/subscriptions/new",
            name: "CustomerSubscriptionsNew",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/Subscription/NewSubscription.vue")
          },
          {
            path: "/customer/account",
            name: "CustomerAccount",
            meta: { requiresAuth: true },
            component: () => import("../views/Customer/Account.vue")
          }
        ]
      },
      {
        path: "/subscriptionplan",
        name: "SubscriptionPlan",
        meta: { requiresAuth: true },
        component: () => import("../views/SubscriptionPlan/Layout/Principal.vue"),
        children: [
          {
            path: "/subscriptionplan/dashboard",
            name: "SubscriptionPlanDashboard",
            meta: { requiresAuth: true },
            component: () => import("../views/SubscriptionPlan/Dashboard.vue")
          },
          {
            path: "/subscriptionplan/benefits",
            name: "SubscriptionPlanBenefits",
            meta: { requiresAuth: true },
            component: () => import("../views/SubscriptionPlan/Benefits/Main.vue")
          },
          // {
          //   path: "/subscriptionplan/new",
          //   name: "SubscriptionPlanNew",
          //   meta: { requiresAuth: true },
          //   component: () => import("../views/SubscriptionPlan/New.vue")
          // },
        ]
      },
 /*      {
        path: "/product",
        name: "Product",
        meta: { requiresAuth: true },
        component: () => import("../views/Product/Layout/Principal.vue")
      }, */
      {
        path: "/pre_owned",
        name: "PreOwned",
        meta: { requiresAuth: true },
        component: () => import("../views/PreOwned/Layout/Principal.vue"),
        children: [
          {
            path: "/pre_owned/lists_by_user",
            name: "PreOwnedListsByUser",
            meta: { requiresAuth: true },
            component: () => import("../views/PreOwned/ListsByUser.vue")
          },
          {
            path: "/pre_owned/lists_by_customer",
            name: "PreOwnedListsByCustomer",
            meta: { requiresAuth: true },
            component: () => import("../views/PreOwned/ListsByCustomer.vue")
          },
          {
            path: '/pre_owned/:list_id',
            name: 'ListProducts',
            component: () => import("../views/PreOwned/ListProducts.vue")
          },
          {
            path: '/pre_owned/:list_id/:list_product_id',
            name: 'ListProductRatings',
            component: () => import("../views/PreOwned/ListProductRatings.vue")
          },
          {
            path: '/pre_owned/criterias',
            name: 'Criterias',
            component: () => import("../views/PreOwned/Criterias.vue")
          }
        ]
      }
      // {
      //   path: "/customer/new",
      //   name: "NewCustomers",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/Customer/NewCustomer.vue"),
      // },
      // {
      //   path: "/campaign/list",
      //   name: "ListCampaign",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/Campaign/ListCampaign.vue"),
      // },
      // {
      //   path: "/store",
      //   name: "Store",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/Store/Layout/Principal.vue"),
      //   children: [
      //     {
      //       path: "/store/dashboard",
      //       name: "StoreDashboard",
      //       meta: { requiresAuth: true },
      //       component: () => import("../views/Store/Dashboard.vue"),
      //     },
      //     {
      //       path: "/store/sell",
      //       name: "StoreSell",
      //       meta: { requiresAuth: true },
      //       component: () => import("../views/Store/Sell.vue"),
      //     },
      //     {
      //       path: "/store/transfer",
      //       name: "StoreTransfer",
      //       meta: { requiresAuth: true },
      //       component: () => import("../views/Store/Transfer.vue"),
      //     },
      //     {
      //       path: "/store/orders",
      //       name: "StoreOrders",
      //       meta: { requiresAuth: true },
      //       component: () => import("../views/Store/Orders.vue"),
      //     },
      //     {
      //       path: "/store/mystore",
      //       name: "StoreMyStore",
      //       meta: { requiresAuth: true },
      //       component: () => import("../views/Store/MyStore.vue"),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/Authentication/Layout/Principal.vue"),
    children: [
      {
        path: "/auth/login",
        name: "AuthLogin",
        component: () => import("../views/Authentication/Login.vue"),
      },
      {
        path: "/auth/register",
        name: "AuthRegister",
        component: () => import("../views/Authentication/Register.vue")
      },
      {
        path: "/auth/forgotten",
        name: "AuthForgotten",
        component: () => import("../views/Authentication/Forgotten.vue")
      },
      {
        path: "/auth/recovery",
        name: "AuthRecovery",
        component: () => import("../views/Authentication/Recovery.vue")
      }
    ]
  },
  {
    path: "/dropheroes",
    name: "DropHeroes",
    component: () => import("../views/LP/Layout/Subscriptions.vue"),
    children: [
      {
        path: "/dropheroes/boardgames",
        name: "DropHeroesBoardGames",
        component: () => import("../views/LP/DropHeroes/BoardGame.vue"),
      }
    ]
  },
  {
    path: "/test",
    name: "TestCode",
    component: () => import("../test/test.vue")
  }
  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () => import("../views/Authentication/Login.vue"),
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/Authentication/Register.vue"),
  // },
];

const router = createRouter({
  history: process.env.VUE_APP_ENVIRONMENT == "mobile" ? createWebHashHistory() : createWebHistory(process.env.BASE_URL),
  routes,
});

const auth = {
  isLoggedIn: () => {
    if (localStorage.getItem("dropAuthToken") != undefined) {
      const loginDate = localStorage.getItem("loginDate");
      if ((new Date().getTime() - loginDate <= 900000) || process.env.VUE_APP_ENVIRONMENT == 'development') {
        localStorage.setItem("loginDate", new Date().getTime());
        return true;
      }
    }

    return false;
  },
};

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !auth.isLoggedIn()) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    router.push("/auth/login");
  }

  next();
});

export default router;
