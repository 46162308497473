export default {
  state: () => ({
    customerBalance: 0
  }),
  mutations: {
    setCustomerBalance (state, payload) {
      state.customerBalance = payload
    }
  }
}
