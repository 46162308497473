export default {
  state: () => ({
    loading: false
  }),
  mutations: {
    setLoading (state, payload) {
      state.loading = payload
    }
  },
  actions: {
    showLoading (context) {
      context.state.loading = true
    },
    hideLoading (context) {
      context.state.loading = false
    }
  }
}
