export default {
  state: () => ({
    customer: {}
  }),
  mutations: {
    setAccountCustomer (state, payload) {
      state.customer = payload
    }
  },
  actions: {
    accountCustomerStateIsEmpty (context) {
      const obj = context.state.customer
      if ( Object.keys(obj).length === 0 ) {
        return true;
      }

      return false;
    }
  }
}
