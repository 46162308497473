import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Maska from "maska";
import Notifications from '@kyvg/vue3-notification'
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";

import money from 'v-money3';

import './styles/app.css';

loadFonts();

const app = createApp(App)
app.use(router)
app.use(store)
app.use(vuetify)
app.use(Maska)
app.use(money)
app.use(Notifications)

app.mount("#app");
